'use client';

import React, { useState } from 'react';

import isPropValid from '@emotion/is-prop-valid';
import { useServerInsertedHTML } from 'next/navigation';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';

// Custom filter function
const shouldForwardProp = prop => {
  // List props that shouldn't be forwarded to DOM
  const nonDOMProps = [
    'maxWidth',
    'shouldScroll',
    'isCurrent',
    'active',
    'position',
    'visible',
    'mt',
    'like',
    'justify',
    'desktop',
    'tablet',
  ];

  return isPropValid(prop) && !nonDOMProps.includes(prop);
};

export default function StyledComponentsRegistry({ children }) {
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== 'undefined') return <>{children}</>;

  return (
    <StyleSheetManager
      sheet={styledComponentsStyleSheet.instance}
      shouldForwardProp={shouldForwardProp}
    >
      {children}
    </StyleSheetManager>
  );
}
