/**
 * @description Actions that user can do
 */
export const ACTIONS = {
  visit_admin: 'visit-admin',
  // comments
  add_comment: 'add-comment',
  edit_comment: 'edit-comment',
  delete_comment: 'delete-comment',
  preview: 'preview',
  // search
  copy_links: 'copy-links',
};
