import { ACTIONS } from './actions';
import { ROLES } from './roles';

/**
 * @description Config of permissions (admin can do all actions)
 */
export const permissionsConfig = {
  // Base role
  [ROLES.SUBSCRIBER]: [ACTIONS.add_comment, ACTIONS.copy_links],

  // Employee role
  [ROLES.EMPLOYEE]: [ACTIONS.preview, ACTIONS.add_comment, ACTIONS.copy_links],

  // Admin roles
  [ROLES.ADMIN]: [
    ACTIONS.visit_admin,
    ACTIONS.add_comment,
    ACTIONS.edit_comment,
    ACTIONS.delete_comment,
    ACTIONS.preview,
    ACTIONS.copy_links,
  ],
  [ROLES.TESTER]: [ACTIONS.preview, ACTIONS.add_comment, ACTIONS.copy_links],
  [ROLES.CONTRIBUTOR]: [
    ACTIONS.preview,
    ACTIONS.add_comment,
    ACTIONS.copy_links,
  ],
  [ROLES.COMMENT_ADMIN]: [
    ACTIONS.add_comment,
    ACTIONS.edit_comment,
    ACTIONS.delete_comment,
  ],
  [ROLES.AUTHOR]: [
    ACTIONS.preview,
    ACTIONS.add_comment,
    ACTIONS.edit_comment,
    ACTIONS.delete_comment,
    ACTIONS.copy_links,
  ],

  // Medical roles
  [ROLES.MEDIC]: [ACTIONS.add_comment],
  [ROLES.MEDICAL_SUPPORT]: [ACTIONS.add_comment],
};
