import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 40px;

    @media screen and (max-width: 770px) {
      scroll-padding-top: 20px;
    }
  }

  // https://github.com/w3c/csswg-drafts/issues/865
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-print-color-adjust: exact;
    background: ${props => props.theme.palette.background.default};
    color: #333;
    font-family: 'Atlas Grotesk', sans-serif;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    overflow: auto;
    scroll-behavior: smooth;
    width: 100%;
  }


  iframe,
  video {
    max-width: 100%;
  }

  @media print {
    body {
      font-size: 12px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  @media screen and (max-width: 770px) {
    table td p {
      margin: 0;
    }
  }
  table.striped tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  table.striped tr th {
    background-color: #e05d2d;
    font-weight: bold;
    color: white;
    border: none;
    text-align: left;
  }
  th,
  td {
    padding: 2px 5px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }

  h1,
  h2,
  h3 {
    font-weight: 900;
    line-height: 1.1;
  }

  h2 {
    line-height: 1.3;
  }

  * {
    box-sizing: border-box;
  }

  main img,
  main svg,
  section svg,
  section img {
    height: auto;
  }

  svg {
    height: auto;
    clear: both;
    display: block;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    // transition: all 500ms;
  }
  a.active {
    font-weight: bold;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 0.7em;
    padding-left: 1em;
    margin-bottom: 0.3em;
    margin-top: 0.3em;
  }

  ol li,
  ul li {
    margin-bottom: 0.3em;
  }

  // skeleton placeholder animation pulsing with grey gradient 
  .pulse {
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
    background-size: 400% 400%;
    -webkit-animation: pulse 1.2s ease-in-out infinite;
            animation: pulse 1.2s ease-in-out infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  html {
    height: 100%;
  }

  .content {
    display: flex;
  }

  .lines {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .lines .line, .lines .thumb {
    background-color: #f5f5f5;
    width: 15rem;
    height: 1rem;
    margin: 0.25rem;
  }
  .lines .thumb {
    height: 7.5rem;
  }

  /* hide on tablet and mobile */
  @media only screen and (max-width: 1024px) {
    #chat-widget-container {
      display: none;
    }
  }
`;

export default GlobalStyles;
