import { decodeJwt } from './decodeJwt';

interface UserData {
  avatar: string;
  email: string;
  exp: number;
  firstName: string;
  group: string;
  iat: number;
  id: string;
  isEmployee: boolean;
  isTeacher: boolean;
  iss: string;
  lastName: string;
  nbf: number;
  roles: string[];
}

type JwtType = string | undefined | null;

export function getUserData(jwt: JwtType = null): UserData | null {
  if (!jwt) return null;

  const jwtPayload = decodeJwt(jwt);

  return jwtPayload;
}
