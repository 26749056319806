'use client';

import React from 'react';

import { AuthContext } from './context';
import { getJwtCookie } from './getJwtCookie';
import { MEDICAL_ROLES } from '@/constants';
import type { UserData } from '@/lib/authentification/decodeJwt';
import { getUserData } from '@/lib/authentification/getUserData';
import { createPermissionChecker } from '@/lib/permissions';
import type { CanFunctionType } from '@/lib/permissions';
import { ACTIONS } from '@/lib/permissions/actions';

export const AuthProvider: React.FC<{
  initialUserData: UserData | null;
  children: React.ReactNode;
}> = ({ initialUserData, children }) => {
  const [user, setUser] = React.useState(initialUserData);
  const [jwtCookie, setJwtCookie] = React.useState<string | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(false);
  const [isMedical, setIsMedical] = React.useState<boolean>(false);

  React.useEffect(() => {
    const cookie = getJwtCookie();
    if (cookie) {
      setJwtCookie(cookie);
    }
  }, []);

  /**
   * @description Check if user can do an action
   */
  const can: CanFunctionType = React.useMemo(() => {
    if (user) {
      return createPermissionChecker(user.roles);
    }
    return () => false;
  }, [user]);

  /**
   * @description Check if user is logged in
   */
  React.useEffect(() => {
    if (user) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [user]);

  /**
   * @description Get user data from JWT cookie
   */
  React.useEffect(() => {
    if (jwtCookie) {
      try {
        const userData = getUserData(jwtCookie);
        if (userData) {
          if (userData.exp > Date.now() / 1000) {
            setUser(userData);
          }
        }
      } catch (error) {
        console.error('Invalid token', error);
      }
    }
  }, [jwtCookie]);

  // Check if user has a medical role
  React.useEffect(() => {
    if (user) {
      const { roles } = user;
      if (roles) {
        const medicalRoles = roles.filter(role => MEDICAL_ROLES.includes(role));
        if (medicalRoles.length > 0) {
          setIsMedical(true);
        }
      }
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{ user, isUserLoggedIn, can, actions: ACTIONS, isMedical }}
    >
      {children}
    </AuthContext.Provider>
  );
};
