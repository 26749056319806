/**
 * @description roles of WP
 * should be loaded from /info endpoint
 */
export const ROLES = {
  ADMIN: 'administrator',
  COMMENT_ADMIN: 'comments_administrator',
  AUTHOR: 'editor',
  EMPLOYEE: 'employee',
  MEDIC: 'medic',
  MEDICAL_SUPPORT: 'medical_support',
  TESTER: 'tester',
  SUBSCRIBER: 'subscriber',
  CONTRIBUTOR: 'contributor',
};
