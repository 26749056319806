'use client';
import React from 'react';

import GTM from 'react-gtm-module';

const GoogleTagManager = () => {
  const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

  React.useEffect(() => {
    if (gtmId) {
      GTM.initialize({ gtmId });
    }
  }, [gtmId]);

  return null;
};

export default GoogleTagManager;
