'use client';

import React from 'react';

import {
  QueryClient,
  QueryClientProvider,
  HydrationBoundary,
  dehydrate,
} from '@tanstack/react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import ThemeProviders from './ThemeProviders';
// import { MEDICAL_ROLES } from '@/constants';
import { AuthProvider } from '@/ui/components/AuthProvider';
import GlobalStateProvider from '@/ui/components/GlobalStateProvider';
import '@/utils/customConsole';

// const getIsMedical = user => {
//   if (!user?.roles) {
//     return false;
//   }

//   return user.roles.some(role => MEDICAL_ROLES.includes(role));
// };

const ClientProviders = ({ children, locale, initialUserData }) => {
  const [reactQueryClient] = React.useState(new QueryClient());

  // const isMedical = getIsMedical(initialUserData);

  return (
    <AuthProvider initialUserData={initialUserData}>
      <QueryClientProvider client={reactQueryClient}>
        <HydrationBoundary state={dehydrate(reactQueryClient)}>
          <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
            language={locale}
            useRecaptchaNet={true}
            useEnterprise={true}
            scriptProps={{
              async: true,
              defer: true,
              appendTo: 'body',
            }}
          >
            <GlobalStateProvider>
              <ThemeProviders>{children}</ThemeProviders>
              {/* isMedical={isMedical} */}
            </GlobalStateProvider>
          </GoogleReCaptchaProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default ClientProviders;
