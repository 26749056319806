import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';

import StyledComponentsRegistry from '@/lib/registry';
import { setTheme } from '@/theme';
import GlobalStyles from '@/ui/styles/GlobalStyles';

export default function ThemeProviders({ children }) {
  // isMedical
  const theme = setTheme(); //{ isMedical }

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledComponentsRegistry>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledComponentsRegistry>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
