import { permissionsConfig } from './permissionsConfig';
import { ROLES } from './roles';

const checkRole = (roles, action) => {
  if (!roles || !action) return false;

  const actionAllowed = roles.some(role =>
    permissionsConfig[role]?.includes(action)
  );
  return actionAllowed;
};

export type CanFunctionType = (action: string) => boolean;

/**
 * @description set roles and
 * used in Auth context provider
 */
export const createPermissionChecker = (roles: string[]): CanFunctionType => {
  /**
   * @description Check if user can do an action
   * @param {string} action
   * @example import { can } from 'containers/App/permissions';
   * @example can(ACTIONS.STEPS.CREATE)
   * @example can('login')
   */
  return action => {
    if (roles.includes(ROLES.ADMIN)) return true;

    return checkRole(roles, action);
  };
};
