// import { log } from '@/utils/logger';

export interface UserData {
  avatar: string;
  email: string;
  exp: number;
  firstName: string;
  group: string;
  iat: number;
  id: string;
  isEmployee: boolean;
  isTeacher: boolean;
  iss: string;
  lastName: string;
  nbf: number;
  roles: string[];
}

// Get the payload part of the JWT
const getPeyloadFromToken = (token: string): string => {
  return token.split('.')[1];
};

const decodePayload = (token: string): string => {
  const base64Url = getPeyloadFromToken(token);

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return decodedPayload;
};

/**
 * @description decodes JWT payload into JSON
 */
export const decodeJwt = (token: string): UserData | null => {
  try {
    const decodedPayload = decodePayload(token);
    const jsonPayload = JSON.parse(decodedPayload);

    return jsonPayload as UserData;
  } catch (error) {
    console.error('Invalid JWT token:', error);
    return null;
  }
};
